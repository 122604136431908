import { useMemo, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { subDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import {
  deleteStudents,
  getStudentsDataByID,
  updateConfig,
  getStatus,
  getFilterStatus,
} from "../../../store/slices/Students/studentsSlice";
import {
  useGetStudentsListDataQuery,
  useUpdateStudentsListFieldsDataMutation,
} from "../../../store/queries/students";
import { toast } from "react-toastify";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { getExportedData, getIsButtonEnable } from "./api";
import {
  updateConfig as profileCUpdateConfig
} from "../../../store/slices/Students/studentsProfileSlice.js";

const useStudentsListing = (dashboard) => {
  const refMenu = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state.students);
  const studentFilterState = useSelector((state) => state.studentsFilter);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  const [selectedItemID, setSelectedItemID] = useState("");

  const [skip, setSkip] = useState(true);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedStatusOption, setSelectedStatusOption] = useState(null);
  const [statusOptions, setStatusdOptions] = useState([]);

  const options = [
    { value: "", label: "All" },
    { value: "this_month", label: "This Month" },
  ];

  const { showCreateModal, activeFilter } = useSelector(
    (state) => state.students
  );

  const checkTop = (id) =>
    refMenu[id]?.getBoundingClientRect().top > window.innerHeight / 2;

  const { tableFields, showEditModal } = useSelector((state) => state.global);

  const [showImageModal, setShowImageModal] = useState(false);

  const [updateStudentsFields] = useUpdateStudentsListFieldsDataMutation();

  const [imageData, setImageData] = useState("");

  const debouncedSearchTerm = useDebounce(state.search, 1000);

  const {
    data: queryData = {},
    isFetching,
    // isSuccess,
    isLoading,
    refetch,
  } = useGetStudentsListDataQuery({
    sort_by: state.sortBy,
    sort_order: state.sortOrder,
    search: debouncedSearchTerm,
    start_date: getFormatedDate(state.startDate),
    end_date: getFormatedDate(state.endDate),
    length: state.currentPageSize,
    page: state.currentPage,
    end_date_filter: state.end_date_filter,
    stream_id: studentFilterState?.stream_id,
    course_status: studentFilterState?.course_status,
    course_id: studentFilterState?.course_id,
    branch_id: studentFilterState?.branch_id,
    join_start_date: studentFilterState?.join_start_date,
  });
  useEffect(() => {
    dispatch(getStatus());
    dispatch(getFilterStatus());
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (state?.statusData?.length > 0) {
      setStatusdOptions(
        state?.statusData?.map((option) => {
          return {
            value:
              option?.statusType !== undefined
                ? option?.statusType.toString()
                : "",
            label: option?.title,
          };
        })
      );
    }
    //eslint-disable-next-line
  }, [state?.statusData?.length > 0]);

  useEffect(() => {
    if (state.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [state.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: state.startDate,
    endDate: state.endDate,
    key: "selection",
  });

  // useEffect(() => {
  //   dispatch(
  //     updateConfig((state) => {
  //       state.selectedItemsDetails = enquiryViewData?.data ?? "";
  //     })
  //   );

  //   // eslint-disable-next-line
  // }, [enquiryViewData]);

  const hasCreatePermission = useMemo(() => {
    let permission = queryData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [queryData]);
  const hasEditPermission = useMemo(() => {
    let permission = queryData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [queryData]);
  const hasViewPermission = useMemo(() => {
    let permission = queryData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
  }, [queryData]);
  const hasDeletePermission = useMemo(() => {
    let permission = queryData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [queryData]);

  const handleSort = (label) => {
    if (state.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = state.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    dispatch(
      updateConfig((state) => {
        state.end_date_filter = selectedOption.value;
      })
    );
  };

  const handleStatusChange = (selectedStatusOption) => {
    setSelectedStatusOption(selectedStatusOption);
    dispatch(
      updateConfig((state) => {
        state.course_status = selectedStatusOption.value;
      })
    );
  };

  const handleFilterClick = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
        state.isFilter = true;
      })
    );
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };
  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    setSkip(() => false);

    dispatch(getStudentsDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };
  const handleEditClick = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedItemsDetails = enquiryViewData?.data ?? "";
    //   })
    // );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      course_name: (feild, data) => (
        <p className="pro-mb-0">{data["courses"][0]?.course_name}</p>
      ),
      course_status: (feild, data) => (
        <p className="pro-mb-0">{data["courses"][0]?.course_status}</p>
      ),
      first_name: (feild, data) => {
        let params = {
          course_id: data["courses"][0]?.course_id,
          student_id: data?.id
        }
        return (
          <p
            className={`pro-mb-0 ${hasViewPermission ? "pro-pnt" : ""} `}
            onClick={() => {
              getIsButtonEnable(params).then((response) => {
                if (response?.data?.status_code === 200) {
                  dispatch(
                    profileCUpdateConfig((state) => {
                      state.isButtonFalse = response?.data?.data?.is_editable;
                    })
                  );
                } else {
                  dispatch(
                    profileCUpdateConfig((state) => {
                      state.isButtonFalse = response?.data?.data?.is_editable;
                    })
                  );
                }
              });
              if (hasViewPermission) {
                navigate({
                  pathname: `/students-profile/${data?.id}`,
                });

                sessionStorage.setItem("active", `${data?.id}`);
              }
            }}
          >
            {data?.[feild]}
          </p>
        );
      },

      image: (feild, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.image_url)}
            />
          </div>
        );
      },
      thumbnail: (feild, data) => {
        return (
          <div className="no-wrap">
            <IconText
              icon={
                <span className="material-symbols-outlined x4">imagesmode</span>
              }
              title={`${"View Photo"}`}
              onClick={() => handleViewImage(data?.thumbnail_url)}
            />
          </div>
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleFilterAction = () => {
    dispatch(
      updateConfig((state) => {
        state.filterShow = !state.filterShow;
      })
    );
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = range.startDate;
        state.endDate = range.endDate;
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: subDays(new Date(), 30),
      endDate: new Date(),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = subDays(new Date(), 30);
        state.endDate = new Date();
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
      })
    );
  };

  const handleDashboardRedirect = (filter) => {
    navigate("/students");
  };

  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteStudents(selectedItemID[0]))
        .unwrap()
        .then((result) => {
          if (result?.message === "Success") {
            toast.success(result?.message);
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );
          } else {
            toast.error(result?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleDateRangeChange = (range) => {
    const startDate = range[0];
    const endDate = range[1];

    setDate({
      startDate,
      endDate,
    });

    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = startDate;
        state.endDate = endDate;
      })
    );
  };
  const handleExcelDownload = (e) => {
    toast.success(`File Downloading...`);
    let paramsData = {
      course_status: studentFilterState?.course_status ?? "",
      stream_id: studentFilterState?.stream_id ?? "",
      course_id: studentFilterState?.course_id ?? "",
      branch_id: studentFilterState?.branch_id ?? "",
      join_start_date: studentFilterState?.join_start_date ?? "",
      end_date_filter: state.end_date_filter ?? "",
      start_date: getFormatedDate(state.startDate) ?? "",
      end_date: getFormatedDate(state.endDate) ?? "",
    };
    getExportedData(paramsData).then((response) => {
      if (response?.status === 200) {
        let dataType = response?.data?.type;
        let binaryData = [];
        binaryData.push(response?.data);
        let downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: dataType })
        );
        downloadLink.setAttribute(
          "download",
          `${moment(Date.now()).format("MM_DD_YYYY_")}${`Student_list`}.xlsx`
        );
        document.body.appendChild(downloadLink);
        downloadLink.click();
      } else {
        toast.error(`Failed to download!`);
      }
    });
  };

  return {
    queryData,
    state,
    currentPage: state.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    isLoading,
    date,
    activeFilter,
    showEditModal,
    tableFields,
    paginationOptions,
    actionOptions,
    showImageModal,
    imageData,
    hasEditPermission,
    hasDeletePermission,
    handleDelete,
    handleDateRangeChange,
    handleFilterAction,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    updateStudentsFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    showDeleteConfirm,
    selectedOption,
    handleStatusChange,
    selectedStatusOption,
    setShowDeleteConfirm,
    handleDeleteAction,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    handleExcelDownload,
    handleChange,
    options,
    statusOptions,
  };
};

export default useStudentsListing;
