import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  emailRegExp,
  numRegExp,
  phoneRegExp,
} from "../../../../utils/functions/table";

import { useSelector } from "react-redux";
// import { useCreateBranchesMutation, useUpdateBranchesMutation,
// } from "../../../../store/queries/branches";
import {
  updateConfig,
  createSubAdmin,
  updateSubAdmin,
} from "../../../../store/slices/SubAdmins/subAdminsSlice";
import countryData from "../../../../utils/components/countryCode";
import { useGetBranchDataQuery } from "../../../../store/queries/subAdmins";

const useSubAdminForm = ({ refetch, closeModal, userTypeData }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const { data: branchData = {} } = useGetBranchDataQuery({});
  const { selectedItemsDetails, is_edit } = useSelector(
    (state) => state.subAdmins
  );

  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.enquiry?.url : ""
  );

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
  );
  const [selectedOption, setSelectedOption] = useState(null);

  const stateRef = useRef(" ");

  useEffect(() => {
    if (selectedItemsDetails !== "") {
      setImageCoverPreview(selectedItemsDetails?.image_url ?? "");
    }
  }, [selectedItemsDetails]);

  const passwordValidation = Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("*Required");
  const passwordValidationUpdate = Yup.string().min(
    8,
    "Password is too short - should be 8 chars minimum."
  );

  const confirmValidation = Yup.string()
    .min(8, "Password is too short - should be 8 chars minimum.")
    .required("*Required");

  const validation = Yup.object({
    first_name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("*Required"),
    last_name: Yup.string().max(50, "max 50 characters allowed"),
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
    user_type: Yup.string().required(`Select user type`),
    branch_id: Yup.string().required("Select branch"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    password: selectedItemsDetails === "" ? passwordValidation : "",
    confirm_password: selectedItemsDetails === "" ? confirmValidation : "",
  });

  const formik = useFormik({
    initialValues: {
      first_name:
        selectedItemsDetails !== "" ? selectedItemsDetails?.first_name : "",
      last_name:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.last_name !== null
            ? selectedItemsDetails?.last_name
            : ""
          : "",
      dial_code:
        selectedItemsDetails !== "" ? selectedItemsDetails?.dial_code : "+91",
      mobile_number:
        selectedItemsDetails !== "" ? selectedItemsDetails?.mobile_number : "",
      email: selectedItemsDetails !== "" ? selectedItemsDetails?.email : "",
      user_type:
        selectedItemsDetails !== "" ? selectedItemsDetails?.user_type : "",
      branch_id:
        selectedItemsDetails !== "" ? selectedItemsDetails?.branch_id : "",
      password:
        selectedItemsDetails !== "" ? selectedItemsDetails?.password : "",
      confirm_password:
        selectedItemsDetails !== ""
          ? selectedItemsDetails?.confirm_password
          : "",
    },

    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      return errors;
    },
    // validationSchema: createEnquiryVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        first_name: values.first_name,
        last_name: values.last_name ? values.last_name : "",
        mobile_number: values.mobile_number,
        dial_code: values.dial_code,
        email: values.email,
        user_type: values?.user_type,
        branch_id: values?.branch_id,
        password: values.password,
        confirm_password: values.confirm_password,
      };

      if (values.image?.name) {
        obj.image = values.image;
      }
      if (selectedItemsDetails !== "") {
        delete obj.password;
        delete obj.confirm_password;
      }

      let formData = new FormData();

      Object.keys(obj).forEach((key) => {
        return formData.append(key, obj[key]);
      });
      if (selectedItemsDetails !== "") {
        dispatch(
          updateSubAdmin({ id: selectedItemsDetails.id, data: formData })
        ).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            closeModal?.();
            refetch();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );
            dispatch(
              updateConfig((state) => {
                state.clearSelection = true;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      } else {
        dispatch(createSubAdmin(formData)).then((response) => {
          if (response?.payload?.status_code === 200) {
            resetForm();
            refetch();
            closeModal?.();
            dispatch(
              updateConfig((state) => {
                state.showCreateModal = false;
              })
            );

            toast.success(response?.payload?.message);
          } else if (response?.payload?.status_code === 400) {
            formik.setErrors(response?.payload?.message);
          } else toast.error(response?.payload?.message);
        });
      }
    },
  });

  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  const handlePhoneNumberChange = (value) => {
    if (value !== formik.values.mobile_number) {
      dispatch(
        updateConfig((state) => {
          state.phoneVerified = {
            verified: false,
            status: false,
          };
        })
      );
    }
    formik.setFieldValue("mobile_number", value);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageCoverPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  let userTypeObj = userTypeData?.data?.filter(
    (item) => item?.id === formik?.values?.user_type
  );
  let branchTypeObj = branchData?.data?.filter(
    (item) => item?.id === formik?.values?.branch_id
  );
  return {
    formik,
    imageCoverPreview,
    selectedItemsDetails,
    profilefileInputRef,
    handleCoverImage,
    handleCloseModal,
    cuntryCodeOptions,
    handlePhoneNumberChange,
    handleShowPassword,
    handleShowConfirmPassword,
    showPassword,
    showConfirmPassword,
    userTypeObj,
    branchTypeObj,
    branchData,
  };
};

export default useSubAdminForm;
