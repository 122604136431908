import { getAxiosInstance } from "../../../api";

export const getExportData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = api.get(
      `/v1/invoice/export?search=${params?.search}&start_date=${params?.start_date}&end_date=${params?.end_date}`,
      { responseType: "blob" }
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
