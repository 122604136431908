import {
  Table,
  HeadingGroup,
  Pagination,
  Button,
  OffCanvasLayout,
  ModalLayout,
  ConfirmationBox,
} from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import { FaSort } from "react-icons/fa";
import useStudentDetails from "./useStudentDetails";
import {
  SimpleReport,
  DataContainer,
} from "@wac-ui-dashboard/wac_component_library";
import EmptyData from "../../Global/EmptyData";

import Style from "./studentDetails.module.scss";
import KanBoardListing from "../../Global/KanBoardListing";
import TimeScheduleForm from "./AssignTimeScheduleForm";
import EditForm from "./EditForm";
import UpdateNewCourseForm from "./UpdateNewCourseForm";
import AssignFacultyForm from "./AssignFaculty";

const StudentDetails = () => {
  const {
    profilePaymentList,
    profileCourseList,
    profileState,
    selectedId,
    // closeModal,
    paginationOptions,
    isCourseFetching,
    isPaymentFetching,
    headerDetails,
    handleTableFilter,
    handleLoadMore,
    handleAssignAction,
    updateCourseStatus,
    handleCreateClick,
    handlePaymentPagination,
    handleCoursePagination,
    handlePaymentPageSize,
    handleCoursePageSize,
    handlePaymentSort,
    handleCourseSort,
    handleEditAction,
    refetchDataContainer,
    getCourseRow,
    getPaymentRow,
    courseRefetch,
    isButtonFalse,
    handleUpdateNewCourseClick,
    handleNewCourseEditAction,
    showDeleteConfirm,
    handleDelete,
    handleDeleteAction,
    setShowDeleteConfirm,
    handleAssignFaculty,
    hasAssignPermission
  } = useStudentDetails();

  return (
    <>
      <div className="pro-m-4 pro-mx-5">
        <DataContainer propStyle={{ root: Style.container_root }}>
          <SimpleReport
            data={headerDetails}
            propStyle={{ item: Style.report_root }}
          />
          <Button
            className={"pro-btn-primary lg "}
            onClick={() => handleCreateClick()}
          >
            Add New Course
          </Button>
        </DataContainer>
        <div className="pro-d-flex pro-justify-between pro-items-center pro-gap-3 pro-pt-4">
          <div className="pro-btn-group pro-d-flex pro-ms-auto">
            <button
              className={`pro-btn pro-btn-outline lg ${
                profileState.activeFilter === 1 ? "active pro-no-point" : ""
              }
                  `}
              onClick={() => handleTableFilter(profileState.activeFilter)}
            >
              <span className={`material-symbols-outlined  ${Style.aling_btn}`}>
                format_align_left
              </span>
            </button>

            <button
              className={`pro-btn pro-btn-outline lg ${
                profileState.activeFilter === 2 ? "active pro-no-point" : ""
              }`}
              onClick={() => handleTableFilter(profileState.activeFilter)}
            >
              <span className="material-symbols-outlined">density_small</span>
            </button>
          </div>
        </div>
      </div>
      <div className={` pro-mx-5 ${Style.table_container}`}>
        <div className={`pro-pt-3 pro-pb-5 `}>
          {profileCourseList?.data?.rows?.courses?.length === 0 ? (
            <EmptyData />
          ) : (
            <>
              {profileState.activeFilter === 1 ? (
                <>
                  {profileCourseList?.data?.rows?.courses?.map(
                    (value, index) => {
                      return (
                        <div className={Style.loop_list}>
                          <div className="pro-d-flex pro-items-center pro-gap-4">
                            <h4 className="pro-ttl h4 pro-mb-0">
                              {value?.course_name ?? ""}
                            </h4>

                            <div className="pro-btn-group pro-d-flex">
                              <Button
                                className={"pro-btn-primary sm "}
                                onClick={() => handleDelete(value?.id)}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                          <Table
                            key={index}
                            data={value?.modules || []}
                            uniqueID={"id"}
                            fields={profileCourseList?.data?.field}
                            showCheckBox={true}
                            SortIcon={<FaSort />}
                            editIcon={
                              <span className="material-symbols-outlined">
                                edit
                              </span>
                            }
                            deleteIcon={
                              <span className="material-symbols-outlined">
                                view_timeline
                              </span>
                            }
                            assignIcon={
                              <span className="material-symbols-outlined">
                                <span className="material-symbols-outlined">
                                  view_timeline
                                </span>
                              </span>
                            }
                            assignText={`Assign Time Table`}
                            handleSort={handleCourseSort}
                            getRow={getCourseRow}
                            handleAssign={(e) => handleAssignAction(e, value)}
                            handleEdit={(e) => handleEditAction(e, value)}
                            handleDelete={(e) => handleAssignFaculty(e, value)}
                            loading={isCourseFetching}
                            perpage={profileState?.courseCurrentPageSize}
                            assignable={true}
                            editable={true}
                            deletable={hasAssignPermission}
                            deleteText={"Assign Faculty"}
                            multiSelect={false}
                            clear={profileState.clearSelection}
                            extraClassName={Style.table_wrap}
                          />
                        </div>
                      );
                    }
                  )}
                </>
              ) : (
                <>
                  {profileCourseList?.data?.rows?.courses?.map(
                    (value, index) => {
                      return (
                        <div className={Style.loop_list}>
                          <div className="pro-d-flex pro-items-center pro-gap-4 pro-justify-between">
                            <h4 className="pro-ttl h4 pro-mb-0">
                              {value?.course_name ?? ""}
                            </h4>

                            <div className="pro-btn-group pro-d-flex">
                              <Button
                                className={"pro-btn-primary lg "}
                                // onClick={() => handleUpdateNewCourseClick()}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                          <KanBoardListing
                            key={index}
                            updateData={updateCourseStatus}
                            data={value?.modules ?? []}
                            queryStatus={profileState?.queryStatus}
                            tableStructure={value.status}
                            handleLoadMore={handleLoadMore}
                            refetchDataContainer={courseRefetch}
                            studentCourse={true}
                          />
                        </div>
                      );
                    }
                  )}
                </>
              )}
            </>
          )}

          {profileCourseList?.data?.rows?.Student?.total > 10 &&
            profileState.activeFilter === 1 && (
              <div className="pro-d-flex pro-justify-center pro-pt-4">
                <Pagination
                  currentPage={profileState.courseCurrentPage}
                  totalPageCount={Math.ceil(
                    profileCourseList?.data?.rows?.Student?.total /
                      profileState.courseCurrentPageSize
                  )}
                  defaultValue={paginationOptions?.filter(
                    (item) => item.value === profileState?.courseCurrentPageSize
                  )}
                  onPageChange={handleCoursePagination}
                  options={paginationOptions}
                  onActionChange={handleCoursePageSize}
                  // center
                />
              </div>
            )}
        </div>
      </div>

      <div className={`pro-mx-5`}>
        <HeadingGroup
          title={"Payment History"}
          extraClassName={`pro-pt-4`}
        ></HeadingGroup>
        <div className={`pro-pt-3 pro-pb-5 `}>
          {profilePaymentList?.data?.rows?.data?.length === 0 ? (
            <EmptyData />
          ) : (
            <Table
              data={profilePaymentList?.data?.rows?.data || []}
              uniqueID={"_id"}
              fields={profilePaymentList?.data?.field}
              showCheckBox={false}
              SortIcon={<FaSort />}
              editIcon={<span className="material-symbols-outlined">edit</span>}
              deleteIcon={
                <span className="material-symbols-outlined">delete</span>
              }
              handleSort={handlePaymentSort}
              getRow={getPaymentRow}
              loading={isPaymentFetching}
              perpage={profileState?.paymentCurrentPageSize}
              assignable={false}
            />
          )}

          {profilePaymentList?.data?.rows?.total > 10 && (
            <div className="pro-d-flex pro-justify-center pro-pt-4">
              <Pagination
                currentPage={profileState.paymentCurrentPage}
                totalPageCount={Math.ceil(
                  profilePaymentList?.data?.rows?.total /
                    profileState.paymentCurrentPageSize
                )}
                defaultValue={paginationOptions?.filter(
                  (item) => item.value === profileState?.paymentCurrentPageSize
                )}
                onPageChange={handlePaymentPagination}
                options={paginationOptions}
                onActionChange={handlePaymentPageSize}
                // center
              />
            </div>
          )}
        </div>
      </div>
      <OffCanvasLayout
        show={profileState.showAssignModal}
        handleClose={handleAssignAction}
        title={`Assign Time Table`}
        backdrop="static"
        className={Style.timeslot_canvas}
      >
        <TimeScheduleForm
          courseRefetch={courseRefetch}
          studentData={profileCourseList?.data}
          studentId={selectedId}
          courseId={profileState?.course}
        />
      </OffCanvasLayout>
      <OffCanvasLayout
        show={profileState.showAssignFacultyModal}
        handleClose={handleAssignFaculty}
        title={`Assign Faculty`}
        backdrop="static"
      >
        <AssignFacultyForm
          courseRefetch={courseRefetch}
        />
      </OffCanvasLayout>
      <ModalLayout
        centered={false}
        show={profileState.showEditModal}
        handleClose={handleEditAction}
        closeIcon={<span className="material-symbols-outlined">close</span>}
        title={`Update`}
        backdrop="static"
      >
        <div className="pro-m-5">
          <EditForm />
        </div>
      </ModalLayout>

      <OffCanvasLayout
        show={profileState.showCourseUpdateModal}
        handleClose={handleNewCourseEditAction}
        title={profileState?.is_edit ? "Update New Course" : "Add New Course"}
        backdrop="static"
      >
        <UpdateNewCourseForm
          courseRefetch={courseRefetch}
          studentData={profileCourseList?.data}
        />
      </OffCanvasLayout>

      <ModalLayout
          centered={false}
          show={showDeleteConfirm}
          handleClose={setShowDeleteConfirm}
          backdrop="static"
        >
          <div className="pro-m-5">
            <ConfirmationBox
              title={`Are you sure you want to delete?`}
              isRight={true}
              cancelText={`No`}
              submitText={`Yes`}
              cancelAction={setShowDeleteConfirm}
              submitAction={handleDeleteAction}
            >
              ConfirmationBox
            </ConfirmationBox>

            {true && <span className="error-message">{true}</span>}
          </div>
        </ModalLayout>
    </>
  );
};

export default StudentDetails;
