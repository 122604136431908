import { useFormik } from "formik";
import * as Yup from "yup";
import {
  updateConfig,
  updateTimeSlotData,
} from "../../../../store/slices/TimeTable/timeTableSlice.js";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { toast } from "react-toastify";
import {
  useGetDaysListDataQuery,
  useGetFacultyListDataQuery,
} from "../../../../store/queries/timeTable/index.js";
import {
  useGetCourseDataQuery,
  useGetStudentsListDataQuery,
} from "../../../../store/queries/students/index.js";
import { getCourseModuleList } from "../../../../store/slices/Students/studentsSlice.js";

const useEditTimeSlotForm = ({ refetchEditTimeSchedule, refetch }) => {
  const dispatch = useDispatch();
  const { editTimeScheduleData } = useSelector((state) => state.timetable);

  const { courseModuleList } = useSelector((state) => state.students);

  const { data: facultyData } = useGetFacultyListDataQuery();
  const { data: daysListData } = useGetDaysListDataQuery();
  const { data: courseListData } = useGetCourseDataQuery();
  const { data: studentListData } = useGetStudentsListDataQuery();
  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));
  const daysList = daysListData?.data?.map(({ day, id }) => ({
    label: day,
    value: id,
  }));
  const courseList = courseListData?.data?.map(({ name, id }) => ({
    label: name,
    value: id,
  }));

  const moduleList = courseModuleList?.map(({ title, id }) => ({
    label: title,
    value: id,
  }));

  const studentList = studentListData?.data?.rows?.students?.data?.map(
    ({ full_name, id }) => ({
      label: full_name,
      value: id,
    })
  );

  useEffect(() => {
    if (editTimeScheduleData !== "") {
      formik.setValues({
        faculty_id: editTimeScheduleData?.faculty_id ?? "",
        course_id: editTimeScheduleData?.course_id ?? "",
        course_module_id: editTimeScheduleData?.course_module_id ?? "",
        student_id: editTimeScheduleData?.student_id ?? "",
        day_id: editTimeScheduleData?.day_id ?? "",
        time_slot_ids: editTimeScheduleData?.time_slot_ids ?? [],
      });
    }
    //eslint-disable-next-line
  }, [editTimeScheduleData]);

  useEffect(() => {
    if (editTimeScheduleData?.course_id) {
      dispatch(getCourseModuleList(editTimeScheduleData?.course_id));
    }
    //eslint-disable-next-line
  }, [editTimeScheduleData?.course_id]);

  const formik = useFormik({
    initialValues: {
      faculty_id: "",
      course_id: "",
      course_module_id: "",
      student_id: "",
      day_id: "",
      time_slot_ids: [],
    },
    validationSchema: Yup.object({
      time_slot_ids: Yup.array()
        .min(1, "Select Time Slots")
        .required("Select Time Slots"),
    }),
    onSubmit: (values) => {
      let params = {
        ...values,
      };
      updateTimeSlotData(params).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success(`Details updated successfully`);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetchEditTimeSchedule();
          refetch();
          closeModal();
        } else {
          toast.error(`Something went wrong!`);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
          refetchEditTimeSchedule();
          refetch();
          closeModal();
        }
      });
    },
  });

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = !state.showCreateModal;
      })
    );
  };

  let facultyObj = facultyList?.filter(
    (item) => item?.value === formik?.values?.faculty_id
  );
  let courseObj = courseList?.filter(
    (item) => item?.value === formik?.values?.course_id
  );
  let moduleObj = moduleList?.filter(
    (item) => item?.value === formik?.values?.course_module_id
  );
  let daysObj = daysList?.filter(
    (item) => item?.value === formik?.values?.day_id
  );
  let studentObj = studentList?.filter(
    (item) => item?.value === formik?.values?.student_id
  );
  return {
    formik,
    closeModal,
    daysList,
    courseList,
    facultyObj,
    moduleObj,
    courseObj,
    moduleList,
    studentList,
    facultyList,
    daysObj,
    studentObj,
  };
};

export default useEditTimeSlotForm;
