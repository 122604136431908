import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  emailRegExp,
  numRegExp,
  phoneRegExp,
} from "../../../../utils/functions/table";

import { useSelector } from "react-redux";
// import { useCreateBranchesMutation, useUpdateBranchesMutation,
// } from "../../../../store/queries/branches";
import countryData from "../../../../utils/components/countryCode";
import {
  students,
  useGetBasicDataQuery,
  useGetBasicOptionDataQuery,
  useGetFacultyDataQuery,
} from "../../../../store/queries/students";
import {
  createStudents,
  getStreams,
  updateStudents,
  updateConfig,
  getNationality,
  getCourseModuleList,
} from "../../../../store/slices/Students/studentsSlice";
import {
  useGetBranchDataQuery,
  useGetCourseListDataQuery,
} from "../../../../store/queries/courses";

const useStudentForm = ({ refetch, closeModal, enrollData, edit }) => {
  const dispatch = useDispatch();

  const profilefileInputRef = useRef(null);
  const [showPassword, setShowPassword] = useState(true);
  const [showConfirmPassword, setShowConfirmPassword] = useState(true);
  const {
    streamData,
    selectedItemsDetails,
    selectedCourseId,
    courseModuleList,
    is_edit,
    nationalityData,
  } = useSelector((state) => state.students);
  const { data: optionsData = {}, isSuccess: isOptionDataSuccess } =
    useGetBasicDataQuery({});
  const { data: courseData = {}, isSuccess: isOptionData } =
    useGetCourseListDataQuery({});
  const { data: branchData = {}, isSuccess: isOptionDatas } =
    useGetBranchDataQuery({});
  const { data: dropDownData = {}, isSuccess: isDropDownDataSuccess } =
    useGetBasicOptionDataQuery({});
  const { data: facultyData = {}, isSuccess: isDataSuccess } =
    useGetFacultyDataQuery({});

  const [imagePreview, setImagePreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.rows?.url : ""
  );

  const [imageCoverPreview, setImageCoverPreview] = useState(
    selectedItemsDetails !== "" ? selectedItemsDetails?.image_url : ""
  );

  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));
  const moduleList = courseModuleList?.map(({ title, id }) => ({
    label: title,
    value: id,
  }));
  const options = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Others" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);

  const stateRef = useRef(" ");
  useEffect(() => {
    dispatch(getStreams());
    dispatch(getNationality());
  }, []);

  useEffect(() => {
    if (courseModuleList?.length !== 0) {
      courseModuleList?.map((item, itemIndex) => {
        formik?.setFieldValue(
          `courses.${itemIndex}.course_module_id`,
          item?.id
        );
        formik?.setFieldValue(`courses.${itemIndex}.faculty_id`, "");
        formik?.setFieldValue(`courses.${itemIndex}.module_end_date`, "");
        formik?.setFieldValue(`courses.${itemIndex}.module_start_date`, "");
      });
    }
    //eslint-disable-next-line
  }, [courseModuleList?.length]);
  // useEffect(() => {
  //   if (selectedItemsDetails !== "") {
  //     setImageCoverPreview(selectedItemsDetails?.rows?.image_url ?? "");
  //   }

  //   if (selectedItemsDetails !== "" && is_edit) {
  //     Object.keys(selectedItemsDetails?.rows || {}).forEach((key) => {
  //       if (key === "gender") {
  //         const result = options?.find(
  //           (value) => value?.value === selectedItemsDetails?.rows?.[key]
  //         );

  //         if (result) {
  //           setSelectedOption(result);
  //         }
  //       }
  //     });
  //   }
  // }, [selectedItemsDetails]);

  useEffect(() => {
    if (selectedCourseId) {
      dispatch(getCourseModuleList(selectedCourseId));
    }
    //eslint-disable-next-line
  }, [selectedCourseId]);

  const validateFunction = (value) => {
    if (value > formik.values.total_amount) {
      return "Received amount must be less than or equal to Total amount";
    }
    return undefined; // No error
  };

  const validation = Yup.object({
    first_name: Yup.string()
      .min(2, "The first name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .matches(/^[a-zA-Z\s]+$/, "Invalid name Format")
      .required("*Required"),
    last_name: Yup.string()
      .max(50, "max 50 characters allowed")
      .required("*Required"),
    mobile_number: Yup.string()
      .matches(phoneRegExp, "Please enter a valid phone number")
      .min(7, "The phone number must be between 7 and 14 digits")
      .max(14, "The phone number must be between 7 and 14 digits")
      .required("*Required"),
    email: Yup.string()
      .matches(emailRegExp, "Please enter a valid email")
      .required("*Required"),
    city: Yup.string().required("*Required"),
    state_id: Yup.string().required("*Required"),
    country_id: Yup.string().required("*Required"),
    branch_id: Yup.string().required("*Required"),
    course_id:
      selectedItemsDetails === "" ? Yup.string().required("*Required") : "",
    courses:
      selectedItemsDetails === ""
        ? Yup.array().of(
            Yup.object().shape({
              course_module_id: Yup.string(),
              faculty_id: Yup.string().required("Select Faculty"),
              module_start_date: Yup.string().required("*Required"),
              module_end_date: Yup.string().required("*Required"),
            })
          )
        : "",
    stream_id: Yup.string().required("*Required"),
    // weekly_hours: Yup.string()
    //   .matches(numRegExp, "Enter hours in number")
    //   .required("*required"),
    address_one: Yup.string().required("*Required"),
    total_amount:
      selectedItemsDetails === ""
        ? Yup.string()
            .matches(/^[0-9]*$/, "Total Amount must contain only numbers")
            .required("*Required")
        : "",
    received_amount:
      selectedItemsDetails === ""
        ? Yup.string()
            .test(
              "is-less-than-or-equal",
              "Received amount must be less than or equal to total amount",
              function (value) {
                const totalAmount = this.parent.total_amount; // Access the totalAmount field
                return value <= totalAmount;
              }
            )
            .matches(/^[0-9]*$/, "Received Amount must contain only numbers")
            .required("*Required")
        : "",
    qualification_id: Yup.string().required("*Required"),

    gender: Yup.string().required("*Required"),
    date_of_birth: Yup.string().required("*Required"),
    join_date: Yup.string()
      .test(
        "differentDates",
        "Join date cannot be the same as Date of Birth",
        function (value) {
          const { date_of_birth } = this.parent;
          return value !== date_of_birth;
        }
      )
      .required("*Required"),
    zipcode: Yup.string()
      .matches(/^[0-9]+$/, "Zipcode must contain only numeric digits")
      .max(10, "max 10 characters allowed"),
    company_name: Yup.string()
      .min(2, "Company name must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .nullable(),
    designation: Yup.string()
      .min(2, "Designation must be at least 2 characters")
      .max(20, "max 20 characters allowed")
      .nullable(),
  });
  let firstName;
  let lastName;
  let nameData = enrollData?.name?.split(" ");
  if (nameData?.length > 1) {
    let remainingValues;
    [firstName, ...remainingValues] = nameData;
    lastName = remainingValues.join(` `);
  }

  const formik = useFormik({
    initialValues: {
      first_name: nameData?.length > 1 ? firstName : enrollData?.name,
      last_name: nameData?.length > 1 ? lastName : "",
      branch_id: "",
      faculty_id: "",
      course_id: "",
      // weekly_hours: "",
      email: enrollData !== "" ? enrollData?.email : "",
      dial_code: enrollData !== "" ? enrollData?.dial_code : "+91",
      mobile_number: enrollData !== "" ? enrollData?.phone_number : "",
      city: "",
      state_id: "",
      country_id: "",
      courses: [
        {
          course_module_id: "",
          faculty_id: "",
          module_start_date: "",
          module_end_date: "",
        },
      ],
      nationality_id: "",
      zipcode: "",
      address_one: "",
      address_two: "",
      total_amount: "",
      received_amount: "",
      qualification_id: "",
      stream_id: enrollData !== "" ? enrollData?.stream_id : "",
      gender: "",
      date_of_birth: "",
      join_date: "",
      course_start_date: "",
      company_name: "",
      designation: "",
    },

    validationSchema: validation,
    validate: (values) => {
      let errors = {};
      if (values.password !== values.confirm_password) {
        errors.confirm_password = "*Passwords does not match";
      }
      return errors;
    },
    // validationSchema: createEnquiryVal,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      let obj = {
        first_name: values.first_name,
        last_name: values.last_name ? values.last_name : "",
        email: values.email,
        course_id: values.course_id,
        // weekly_hours: values?.weekly_hours,
        branch_id: values.branch_id,
        received_amount: values.received_amount,
        total_amount: values.total_amount,
        mobile_number: values.mobile_number,
        dial_code: values.dial_code,
        city: values.city,
        // faculty_id: values.faculty_id,
        zipcode: values.zipcode,
        state_id: values.state_id ? values.state_id : "",
        country_id: values.country_id,
        nationality_id: values.nationality_id,
        address_one: values.address_one,
        address_two: values.address_two,
        qualification_id: values.qualification_id,
        gender: values.gender,
        stream_id: values.stream_id,
        date_of_birth: values.date_of_birth,
        join_date: values.join_date,
        course_start_date: values.join_date,
        company_name: values.company_name,
        designation: values.designation,
      };
      // let editobj = {
      //   first_name: values.first_name,
      //   last_name: values.last_name ? values.last_name : "",
      //   email: values.email,
      //   faculty_id: values.faculty_id,
      //   course_id: values.course_id,
      //   branch_id: values.branch_id,
      //   mobile_number: values.mobile_number,
      //   dial_code: values.dial_code,
      //   city: values.city,
      //   zipcode: values.zipcode,
      //   state_id: values.state_id ? values.state_id : "",
      //   country_id: values.country_id,
      //   address_one: values.address_one,
      //   address_two: values.address_two,
      //   qualification_id: values.qualification_id,
      //   gender: values.gender,
      //   stream_id: values.stream_id,
      //   date_of_birth: values.date_of_birth,
      //   join_date: values.join_date,
      //   course_start_date: values.join_date,
      // };
      // if (values.image?.name) {
      //   obj.image = values.image;
      //   editobj.image = values.image;
      // }
      let newObj = Object.entries(obj);

      newObj = newObj
        .filter((item) => item[1] !== undefined && item[1] !== null)
        .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      let formData = new FormData();

      Object.keys(newObj).forEach((key) => {
        return formData.append(key, newObj[key]);
      });
      if (values.image) {
        obj.image = values.image;
      }
      values?.courses?.map((value, index) => {
        return (
          formData.append(
            `courses[${index}][course_module_id]`,
            value?.course_module_id
          ),
          formData.append(`courses[${index}][faculty_id]`, value?.faculty_id),
          formData.append(
            `courses[${index}][module_start_date]`,
            value?.module_start_date
          ),
          formData.append(
            `courses[${index}][module_end_date]`,
            value?.module_end_date
          )
        );
      });
      dispatch(createStudents(formData)).then((response) => {
        if (response?.payload?.status_code === 200) {
          resetForm();
          dispatch(students.util.invalidateTags(["students"]));
          closeModal?.();
          dispatch(
            updateConfig((state) => {
              state.showCreateModal = false;
            })
          );

          toast.success(response?.payload?.message);
        } else if (response?.payload?.status_code === 400) {
          formik.setErrors(response?.payload?.message);
        } else toast.error(response?.payload?.message);
      });
      // let newObjs = Object.entries(editobj);

      // newObjs = newObjs
      //   .filter((item) => item[1] !== undefined && item[1] !== null)
      //   .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

      // let formDatas = new FormData();

      // Object.keys(newObjs).forEach((key) => {
      //   return formDatas.append(key, newObjs[key]);
      // });
      // if (selectedItemsDetails !== "") {
      //   dispatch(
      //     updateStudents({
      //       id: selectedItemsDetails?.rows?.id,
      //       data: formDatas,
      //     })
      //   ).then((response) => {
      //     if (response?.payload?.status_code === 200) {
      //       resetForm();
      //       closeModal?.();
      //       dispatch(students.util.invalidateTags(["studentDetails"]));

      //       dispatch(
      //         students.util.invalidateTags(["students", "studentDetails"])
      //       );
      //       dispatch(
      //         updateConfig((state) => {
      //           state.showCreateModal = false;
      //         })
      //       );
      //       dispatch(
      //         updateConfig((state) => {
      //           state.clearSelection = true;
      //         })
      //       );

      //       toast.success(response?.payload?.message);
      //     } else if (response?.payload?.status_code === 400) {
      //       formik.setErrors(response?.payload?.message);
      //     } else toast.error(response?.payload?.message);
      //   });
      // } else {
      //   dispatch(createStudents(formData)).then((response) => {
      //     if (response?.payload?.status_code === 200) {
      //       resetForm();
      //       dispatch(students.util.invalidateTags(["students"]));
      //       closeModal?.();
      //       dispatch(
      //         updateConfig((state) => {
      //           state.showCreateModal = false;
      //         })
      //       );

      //       toast.success(response?.payload?.message);
      //     } else if (response?.payload?.status_code === 400) {
      //       formik.setErrors(response?.payload?.message);
      //     } else toast.error(response?.payload?.message);
      //   });
      // }
    },
  });

  const cuntryCodeOptions = useMemo(
    () =>
      countryData?.map((opt) => {
        return {
          value: opt.value,
          label: opt.label,
          title: `${opt.name}(${opt.code}) ${" "}  ${opt.label}`,
          code: opt.code,
        };
      }),
    []
  );

  const handleCountryId = (value) => {
    formik.setFieldValue("country_id", value.id);
  };

  const handlePhoneNumberChange = (value) => {
    if (value !== formik.values.mobile_number) {
      dispatch(
        updateConfig((state) => {
          state.phoneVerified = {
            verified: false,
            status: false,
          };
        })
      );
    }
    formik.setFieldValue("mobile_number", value);
  };

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    formik.setFieldValue("gender", selectedOption.value);
  };

  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
      })
    );
  };

  const handleCoverImage = (e) => {
    const files = e.target.files;

    if (files?.[0]?.size / (1024 * 1024) <= 5) {
      formik.setFieldValue("image", e?.target?.files?.[0]);

      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageCoverPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    } else if (files?.[0]?.size / (1024 * 1024) > 5) {
      toast.error("The image must be less than 5MB in size.");
      formik.setFieldError("image", "The image must be less than 5MB in size.");
    }
  };

  const handleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
    var x = document.getElementById("password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };

  const handleShowConfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPassword(!showConfirmPassword);
    var x = document.getElementById("confirm_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
  };
  const handleNationalityId = (value) => {
    formik.setFieldValue("nationality_id", value.id);
  };

  const handleCourseChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.selectedCourseId = value?.id;
      })
    );
    formik.setFieldValue("courses", []);
    formik.setFieldValue("course_id", value.id);
  };

  return {
    formik,
    imageCoverPreview,
    imagePreview,
    optionsData,
    streamData,
    courseData,
    branchData,
    dropDownData,
    selectedItemsDetails,
    profilefileInputRef,
    handleCoverImage,
    handleCloseModal,
    facultyData,
    cuntryCodeOptions,
    handlePhoneNumberChange,
    handleShowPassword,
    handleNationalityId,
    handleShowConfirmPassword,
    showPassword,
    showConfirmPassword,
    nationalityData,
    stateRef,
    handleCountryId,
    options,
    selectedOption,
    handleChange,
    moduleList,
    facultyList,
    handleCourseChange,
  };
};

export default useStudentForm;
