import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import Select from "react-select";
import useAssignFacultyForm from "./useAssignFacultyForm";

const AssignFacultyForm = ({
  courseRefetch,
}) => {
  const {
    formik,
    facultyObj,
    facultyList,
    handleFacultyChange,
    handleCloseModal,
  } = useAssignFacultyForm({ courseRefetch });

  return (
    <>
      <div className="row">
        <div className="input-wrap pro-mb-4">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Faculty
          </label>
          <Select
            id="faculty_id"
            isClearable={false}
            placeholder={"Select"}
            className={`pro-input lg  ${
              formik.touched.faculty_id && formik.errors.faculty_id && " error"
            }`}
            classNamePrefix="pro-input"
            options={facultyList}
            getOptionValue={(option) => option?.value}
            getOptionLabel={(option) => option?.label}
            value={facultyObj}
            onBlur={formik.handleBlur("faculty_id")}
            onChange={(value) => handleFacultyChange(value)}
          />
          {formik.touched.faculty_id && formik.errors.faculty_id && (
            <span className="error-text">{formik.errors.faculty_id}</span>
          )}{" "}
        </div>
        
        <div
          className={`col-12 pro-d-flex pro-justify-end  offcanvas-footer-sticky-btn`}
        >
          <Button
            className={"pro-btn-link lg pro-px-5"}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>

          <Button
            className={"pro-btn-primary lg pro-ms-3"}
            type="submit"
            onClick={formik.handleSubmit}
          >
            {`Assign`}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AssignFacultyForm;
