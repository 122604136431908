import React from "react";
import Style from "./timeTable.module.scss";
import TimeTableCard from "../../Global/TimeTableCard";
import TimeTableTitle from "../../Global/TimeTableTitle";
import useTimeTable from "./useTimeTable";
import TimeTableDay from "../../Global/TimeTableDay";
import Select from "react-select";
import {
  ConfirmationBox,
  HeadingGroup,
  ModalLayout,
  OffCanvasLayout,
  Pagination,
  SearchFilters,
  Table,
} from "@wac-ui-dashboard/wac_component_library";
// import TimeScheduleForm from "../../Students/StudentDetails/AssignTimeScheduleForm";
import StudentListForm from "../../Faculties/studentListForm";
import OrderColumn from "../../Global/OrderColumn";
import EmptyData from "../../Global/EmptyData";
import EditTimeSlotForm from "./EditTimeSlotForm";

const TimeTable = () => {
  const {
    // isFetching,
    // isLoading,
    daySlot,
    field,
    facultyList,
    courseList,
    timeSlotList,
    daysList,
    activeFilter,
    timeSlot,
    timeSlots,
    timeTableData,
    assignedSlot,
    hasPermission,
    timeTableState,
    showEditModal,
    timeTableEditData,
    paginationOptions,
    assignedStudentsData,
    // handleCloseModal,
    setCourseId,
    setFacultyId,
    handleCardClick,
    closeEditModal,
    handleFacultyChange,
    handleDeleteModal,
    handleEditClick,
    updateTimeTableFields,
    handleDayChange,
    handlePagination,
    handleSearch,
    handlePageSize,
    getRow,
    handleDelete,
    handleTableFilter,
    handleClearClick,
    refetch,
    refetchEditTimeSchedule,
    handleEditAction,
    showCreateModal,
  } = useTimeTable();
  return (
    <>
      <HeadingGroup
        title={"Time Schedule"}
        className={`pro-mb-4`}
        buttonTitle={``}
      />
      <div className={`col-auto pro-pt-3`}>
        <div className="row">
          <div className="col">
            <SearchFilters
              // showActions={activeFilter === 2 ? true : false}
              onSearchInput={handleSearch}
              SearchIcon={
                <span className="material-symbols-outlined"> search </span>
              }
              showClearFilters={
                timeTableState.activeFilter === 2 ? true : false
              }
              handleClear={handleClearClick}
              searchInputProps={{ value: timeTableState?.search }}
              showActions={false}
              searchable={timeTableState.activeFilter === 2 ? true : false}
              handleActionClick={handleEditClick}
              dropDownFilter={
                <>
                  <div className="col-auto dropdown-filter">
                    <Select
                      value={timeTableState.selectedFaculty}
                      onChange={handleFacultyChange}
                      options={facultyList}
                      placeholder="Select Faculty"
                      classNamePrefix={`pro-input`}
                      className={`pro-input lg search-filter-select`}
                    />
                  </div>
                  {activeFilter === 2 ? (
                    <div className="col-auto dropdown-filter">
                      <Select
                        value={timeTableState.selectedDay}
                        onChange={handleDayChange}
                        options={daysList}
                        placeholder="Select Day"
                        classNamePrefix={`pro-input`}
                        className={`pro-input lg search-filter-select`}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </>
              }
              filterChildren={
                <div className="pro-btn-group pro-ms-auto">
                  <button
                    className={`pro-btn pro-btn-outline lg ${
                      activeFilter === 1 ? "active pro-no-point" : ""
                    }`}
                    onClick={() => handleTableFilter(activeFilter)}
                    title="Table View"
                  >
                    <span
                      className={`material-symbols-outlined  ${Style.aling_btn}`}
                    >
                      format_align_left
                    </span>
                  </button>
                  <button
                    className={`pro-btn pro-btn-outline lg ${
                      activeFilter === 2 ? "active pro-no-point" : ""
                    }`}
                    onClick={() => handleTableFilter(activeFilter)}
                    title="Kanban view"
                  >
                    <span className="material-symbols-outlined">
                      density_small
                    </span>
                  </button>
                </div>
              }
            />
          </div>
        </div>
      </div>
      {/* <ModalLayout
        show={timeTableState.showCreateModal}
        handleClose={handleCloseModal}
        title={`Assign Time Slot`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        backdrop="static"
      >
        <TimeScheduleForm
          facultyList={facultyList}
          courseList={courseList}
          timeSlotList={timeSlotList}
          daysList={daysList}
          timeTableData={timeTableData}
          refetch={refetch}
          refetchEditTimeSchedule={refetchEditTimeSchedule}
          setCourseId={setCourseId}
          setFacultyId={setFacultyId}
          handleCloseModal={handleCloseModal}
          assignedStudentsData={assignedStudentsData}
        />
      </ModalLayout> */}
      <ModalLayout
        show={showEditModal}
        handleClose={closeEditModal}
        backdrop="static"
      >
        <div className="pro-m-5">
          <OrderColumn
            title={"Choose which columns you see"}
            refetch={refetch}
            tableFields={field}
            moduleId={timeTableState.editTimeScheduleData?.data?.table_id}
            updateData={updateTimeTableFields}
          />
        </div>
      </ModalLayout>
      {activeFilter === 1 ? (
        <div className={`${Style.root} pro-mt-2 pro-mb-5`}>
          <table className={Style.table}>
            <thead>
              <tr>
                <th>
                  <TimeTableTitle isEmptyTitle />
                </th>
                {Object.values(timeSlot ? timeSlot : {}).map((time, i) => {
                  return (
                    <th key={i}>
                      <TimeTableTitle time={time} />
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {Object.values(daySlot ? daySlot : {}).map((dayKey, dayIndex) => (
                <tr key={dayIndex}>
                  <td>
                    <TimeTableDay isVertical day={dayKey} />{" "}
                  </td>
                  {timeSlots?.map((slotKey, slotIndex) => (
                    <td key={slotIndex}>
                      {assignedSlot[slotKey][dayKey]?.data?.length > 0 ? (
                        <TimeTableCard
                          handleClick={() =>
                            handleCardClick(
                              assignedSlot[slotKey][dayKey],
                              assignedSlot[slotKey][dayKey]?.data[0]
                                ?.course_module_name
                            )
                          }
                          slotData={assignedSlot[slotKey][dayKey]}
                        />
                      ) : (
                        <TimeTableCard isBreak />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : timeTableEditData?.data?.rows?.data?.length === 0 ? (
        <EmptyData />
      ) : (
        <Table
          multiSelect={false}
          data={timeTableEditData?.data?.rows?.data || []}
          uniqueID={"id"}
          fields={field}
          showCheckBox={true}
          getRow={getRow}
          clear={timeTableState.clearSelection}
          perpage={10}
          editable={true}
          deletable={hasPermission?.can_delete ? true : false}
          assignable={false}
          deleteIcon={<span className="material-symbols-outlined">delete</span>}
          editIcon={<span className="material-symbols-outlined">edit</span>}
          handleDelete={handleDeleteModal}
          handleEdit={handleEditAction}
        />
      )}

      <OffCanvasLayout
        show={timeTableState.showCreateModal}
        handleClose={handleEditAction}
        title={`Update`}
        backdrop="static"
      >
        <EditTimeSlotForm
          refetchEditTimeSchedule={refetchEditTimeSchedule}
          refetch={refetch}
          timeSlotList={timeSlotList}
        />
      </OffCanvasLayout>

      <ModalLayout
        show={timeTableState?.showDeleteModal}
        handleClose={handleDeleteModal}
      >
        <div className="pro-m-5">
          <ConfirmationBox
            title={"Are you sure delete the selected Student ?"}
            subTitle={"This action can't be undo "}
            cancelText={"No"}
            cancelAction={handleDeleteModal}
            submitText={"Yes"}
            submitAction={handleDelete}
            isRight={true}
          />
        </div>
      </ModalLayout>
      <ModalLayout
        show={timeTableState.showStudents}
        handleClose={handleCardClick}
        title={`Students Attending Lecture`}
        closeIcon={<span className="material-symbols-outlined"> close </span>}
        // backdrop="static"
      >
        <StudentListForm
          studentListData={timeTableState.showStudentsListData}
          courseName={timeTableState.courseName}
        />
      </ModalLayout>
      {timeTableEditData?.data?.rows?.data?.length > 0 &&
        activeFilter === 2 && (
          <Pagination
            currentPage={timeTableState?.currentPage}
            totalPageCount={Math.ceil(
              timeTableEditData?.data?.rows?.total /
                timeTableState.currentPageSize
            )}
            onPageChange={handlePagination}
            options={paginationOptions}
            onActionChange={handlePageSize}
            center
          />
        )}
    </>
  );
};

export default TimeTable;
