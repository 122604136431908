import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  activeFilter: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: "",
  endDate: "",
  clearSelection: false,
  timeTableData: {},
  showDeleteModal: false,
  assignedStudents: [],
  editTimeScheduleData: [],
  selectedStudent: [],
  courseName: "",
  showStudents: false,
  showStudentsListData: [],
  queryStatus: "idle",
  selectedFaculty: {},
  selectedDay: {},
  sortBy: "",
  sortOrder: "desc",
  search: "",
  is_edit: false,
  showCreateModal: false,
  initial: true,
  IDs: {},
  editSelectedId: ""
};

export const getAssignedStudents = createAsyncThunk(
  "time-table/assigned-students",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/time-table/assigned-students/${params?.courseId}/${params?.facultyId}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// export const getEditTimeSchedule = createAsyncThunk(
//   "time-table/view-time-table",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(
//         `/v1/time-table/view-time-table/${params?.facultyId}/${
//           params?.dayId
//         }?length=${params?.length || "10"}&page=${params?.page || 1}`
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );

export const getEditTimeSlotData = createAsyncThunk(
  "v1/time-table/fetch-student-time-slot",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/time-table/fetch-student-time-slot?time_table_id=${params}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTimeSlotData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/time-table/update-student-time-slot`, params
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
const timeTableSlice = createSlice({
  name: "timetable",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssignedStudents.pending, (state, action) => {
      state.queryStatus = "Pending";
    });
    builder.addCase(getAssignedStudents.fulfilled, (state, action) => {
      state.assignedStudents = action.payload.data;
      state.queryStatus = "fulfilled";
    });
    builder.addCase(getAssignedStudents.rejected, (state, action) => {
      state.queryStatus = "failed";
    });
    builder
      .addCase(getEditTimeSlotData.pending, (state) => {
        state.isLoading = true;
        state.editTimeScheduleData = {};
        state.error = null;
      })
      .addCase(getEditTimeSlotData.fulfilled, (state, action) => {
        state.editTimeScheduleData = action.payload.data.data;
      })
      .addCase(getEditTimeSlotData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = timeTableSlice.actions;
export default timeTableSlice.reducer;
