import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import {
  useGetDaysListDataQuery,
  useGetEditTimeScheduleQuery,
  useGetFacultyListDataQuery,
  useGetTimeSlotListDataQuery,
  useGetTimeTableListDataQuery,
} from "../../../store/queries/timeTable";

import {
  getAssignedStudents,
  getEditTimeSlotData,
  // getEditTimeSchedule,
  updateConfig,
} from "../../../store/slices/TimeTable/timeTableSlice";
import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { deleteStudent } from "./api";
import { useUpdateTimeTableListFieldsDataMutation } from "../../../store/queries/enquires";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const useTimeTable = () => {
  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];
  const [courseId, setCourseId] = useState();
  const [facultyId, setFacultyId] = useState();
  const [courseList, setCourseList] = useState();
  // const [timeSlotList, setTimeSlotList] = useState();
  const timeTableState = useSelector((state) => state.timetable);
  const { showCreateModal } = useSelector((state) => state.timetable);
  const { showEditModal } = useSelector((state) => state.global);
  const { data } = { ...timeTableState?.assignedStudents };
  const assignedStudentsData = data?.map(
    ({ student: { full_name }, student_id }) => ({
      label: full_name ?? "Test",
      value: student_id,
    })
  );
  const debouncedSearchTerm = useDebounce(timeTableState.search, 1000);
  const [updateTimeTableFields] = useUpdateTimeTableListFieldsDataMutation();
  const { data: timeSlotListData } = useGetTimeSlotListDataQuery();
  const dispatch = useDispatch();
  const {
    data: timeTableData = {},
    // isFetching,
    // isSuccess,
    isLoading,
    refetch,
  } = useGetTimeTableListDataQuery({
    faculty: timeTableState.selectedFaculty?.value,
    length: timeTableState?.currentPageSize,
    page: timeTableState?.currentPage,
  });
  const { data: timeTableEditData = {}, refetch: refetchEditTimeSchedule } =
    useGetEditTimeScheduleQuery({
      facultyId: timeTableState.selectedFaculty?.value,
      dayId: timeTableState.selectedDay?.value,
      search: debouncedSearchTerm,
      length: timeTableState.currentPageSize,
      page: timeTableState.currentPage,
    });
  const { data: facultyData } = useGetFacultyListDataQuery();
  const { data: daysListData } = useGetDaysListDataQuery();
  const { day_slot, time_slot, slot_assigned, permissions } = {
    ...timeTableData?.data,
  };
  const { field } = { ...timeTableEditData?.data };

  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));
  const daysList = daysListData?.data?.map(({ day, id }) => ({
    label: day,
    value: id,
  }));

  useEffect(() => {
    // if (!timeTableData?.data) {
    // if (facultyList.length === 0 && daysList.length === 0) {
    dispatch(
      updateConfig((state) => {
        state.selectedFaculty = {
          label: facultyList?.[0]?.label,
          value: facultyList?.[0]?.value,
        };
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedDay = {
          label: daysList?.[0]?.label,
          value: daysList?.[0]?.value,
        };
      })
    );
    // }

    // }
    //eslint-disable-next-line
  }, [facultyList?.[0]?.value, daysList?.[0]?.value]);

  useEffect(() => {
    if (courseId && facultyId) {
      dispatch(
        getAssignedStudents({
          courseId: courseId,
          facultyId: facultyId,
        })
      );
    }
    // if (facultyId) getCourseListData();
    // getFacultyListData();
    // eslint-disable-next-line
  }, [courseId, facultyId]);
  // useEffect(() => {
  //   dispatch(
  //     getEditTimeSchedule({
  //       dayId: timeTableState.selectedDay?.value,
  //       facultyId: timeTableState.selectedFaculty?.value,
  //       length: `10`,
  //       page: "1",
  //     })
  //   );
  //   //eslint-disable-next-line
  // }, [
  //   timeTableState.selectedDay?.value,
  //   timeTableState.selectedFaculty?.value,
  // ]);
  useEffect(() => {
    if (timeTableState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [timeTableState.clearSelection]);

  // const getFacultyListData = () => {
  //   getFacultyList().then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       const facultyData = response?.data?.data?.map(
  //         ({ user: { full_name }, id }) => ({
  //           label: full_name,
  //           value: id,
  //         })
  //       );
  //       setFacultyList(facultyData);
  //     }
  //   });
  // };
  // const getTimeSlotListData = () => {
  //   getTimeSlotList().then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       const timeSlotData = response?.data?.data?.map(({ slot, id }) => ({
  //         label: slot,
  //         value: id,
  //       }));
  //       setTimeSlotList(timeSlotData);
  //     }
  //   });
  // };
  // const getCourseListData = () => {
  //   getCourseList(facultyId).then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       const courseListData = response?.data?.data?.map(({ name, id }) => ({
  //         label: name,
  //         value: id,
  //       }));
  //       setCourseList(courseListData);
  //     }
  //   });
  // };
  // const getDaysListData = () => {
  //   getDaysList().then((response) => {
  //     if (response?.data?.status_code === 200) {
  //       const courseListData = response?.data?.data?.map(({ day, id }) => ({
  //         label: day,
  //         value: id,
  //       }));
  //       setDaysList(courseListData);
  //     }
  //   });
  // };

  // const handleCloseModal = () => {
  //   if (timeTableState.showCreateModal === false) {
  //     // getFacultyListData();
  //     getTimeSlotListData();
  //     getCourseListData();
  //     // getDaysListData();
  //   }
  //   dispatch(
  //     updateConfig((state) => {
  //       state.showCreateModal = !state.showCreateModal;
  //     })
  //   );
  // };
  const handleFacultyChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.selectedFaculty = value;
      })
    );
  };
  const handleDayChange = (value) => {
    dispatch(
      updateConfig((state) => {
        state.selectedDay = value;
      })
    );
  };
  const getRow = (field, data) => {
    const rows = {
      common: (field, data) => <p className="pro-mb-0">{data[field]}</p>,
    };

    return rows?.[field]?.(field, data) ?? rows["common"](field, data);
  };
  // const getEditTimeScheduleData = () => {
  //   dispatch(
  //     getEditTimeSchedule({
  //       dayId: timeTableState.selectedDay?.value,
  //       facultyId: timeTableState.selectedFaculty?.value,
  //       length: `10`,
  //       page: "1",
  //     })
  //   );
  // };
  const handleTableFilter = (activeFilter) => {
    // if (activeFilter === 1) {
    // getEditTimeScheduleData();
    // getDaysListData();
    // }
    // handleHideTooltip();
    activeFilter === 1
      ? dispatch(
          updateConfig((state) => {
            state.activeFilter = 2;
          })
        )
      : dispatch(
          updateConfig((state) => {
            state.activeFilter = 1;
          })
        );
  };
  const handleDeleteModal = (e) => {
    dispatch(
      updateConfig((state) => {
        state.showDeleteModal = !state.showDeleteModal;
        state.selectedStudent = e?.[0];
      })
    );
  };
  const handleDelete = () => {
    deleteStudent(timeTableState.selectedStudent).then((response) => {
      if (response?.data?.status_code === 200) {
        toast.success("Student deleted successfully");
        handleDeleteModal();
        refetchEditTimeSchedule();
        refetch();
        // getEditTimeScheduleData();
      } else {
        toast.error("Something went wrong");
        handleDeleteModal();
      }
    });
    dispatch(
      updateConfig((state) => {
        state.clearSelection = true;
      })
    );
  };
  const timeSlots = Object.keys(time_slot ? time_slot : {});
  const hasPermission = permissions?.reduce((acc, obj) => {
    return { ...acc, ...obj };
  }, {});

  const handleCardClick = (data, courseName) => {
    dispatch(
      updateConfig((state) => {
        state.showStudents = !state.showStudents;
        state.showStudentsListData = data;
        state.courseName = courseName;
      })
    );
  };
  const closeEditModal = () => {
    // setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
  };
  const handleEditClick = () => {
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };
  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };
  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        // state.currentPage = 1;
      })
    );
  };
  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
        state.currentPage = 1;
      })
    );
  };
  const handleClearClick = () => {
    dispatch(updateConfig((state) => (state.search = "")));
    refetch();
  };

  const handleEditAction = (e) => {
    
    let id = e?.[0];
    let selectedData = timeTableEditData?.data?.rows?.data?.filter(
      (item) => item?.id === id
    );
    if (selectedData?.length === 0) {
      dispatch(
        updateConfig((state) => {
          state.showCreateModal = !state.showCreateModal;
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.showCreateModal = !state.showCreateModal;
          state.editSelectedId = e?.[0];
        })
      );
      if (e?.[0]) {
        dispatch(getEditTimeSlotData(e?.[0]));
      }
    }
  };

  return {
    // isFetching,
    // isLoading,
    facultyList,
    courseList,
    field,
    timeSlotList: timeSlotListData?.data,
    showEditModal,
    daysList,
    hasPermission,
    activeFilter: timeTableState.activeFilter,
    timeTableState,
    timeSlots,
    timeTableData,
    daySlot: day_slot,
    timeSlot: time_slot,
    assignedSlot: slot_assigned,
    assignedStudentsData,
    paginationOptions,
    timeTableEditData,
    // handleCloseModal,
    handlePageSize,
    handlePagination,
    setCourseId,
    handleEditClick,
    setFacultyId,
    handleSearch,
    getRow,
    handleCardClick,
    closeEditModal,
    handleTableFilter,
    handleFacultyChange,
    handleDayChange,
    updateTimeTableFields,
    handleDelete,
    handleDeleteModal,
    handleClearClick,
    refetch,
    refetchEditTimeSchedule,
    handleEditAction,
    showCreateModal
  };
};

export default useTimeTable;
