import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ProtectRoute from "../utils/components/ProtectRoute/ProtectRoute";
import useRoutes from "../routes/useRoutes";

const Index = () => {
  const navigate = useNavigate();
  const {
    allowedEnquiresComponent,
    allowedStudenstComponent,
    allowedSettingsComponent,
    // allowedBranchesComponent,
    //  allowedActionLogsComponent,
    allowedFaculitiesComponent,
    allowedEnrollComponent,
    allowedSalesComponent,
    allowedCoursesComponent,
    // allowedInvoicesComponent, allowedSubAdminsComponent,
    allowedDashboardComponent,
    allowedTimeTableComponent,
  } = useRoutes();

  useEffect(() => {
    if (!localStorage.getItem("USER_ACCESS_TOKEN")) {
      navigate("/login");
    } else {
      if (allowedDashboardComponent?.length > 0) {
        navigate(
          `/${allowedDashboardComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedEnquiresComponent?.length > 0) {
        navigate(
          `/${allowedEnquiresComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedStudenstComponent?.length > 0) {
        navigate(
          `/${allowedStudenstComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      // else if (allowedBranchesComponent?.length > 0) {
      //   navigate(
      //     `/${allowedBranchesComponent?.map((item) => item?.path)?.[0]}`
      //   );
      // }
      else if (allowedCoursesComponent?.length > 0) {
        navigate(`/${allowedCoursesComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedEnrollComponent?.length > 0) {
        navigate(`/${allowedEnrollComponent?.map((item) => item?.path)?.[0]}`);
      } else if (allowedTimeTableComponent?.length > 0) {
        navigate(
          `/${allowedTimeTableComponent?.map((item) => item?.path)?.[0]}`
        );
      }
      // else if (allowedInvoicesComponent?.length > 0) {
      //   navigate(
      //     `/${allowedInvoicesComponent?.map((item) => item?.path)?.[0]}`
      //   );
      // }
      // else if (allowedSubAdminsComponent?.length > 0) {
      //   navigate(
      //     `/${allowedSubAdminsComponent?.map((item) => item?.path)?.[0]}`
      //   );
      // }
      // else if (allowedActionLogsComponent?.length > 0) {
      //   navigate(
      //     `/${
      //       allowedActionLogsComponent?.map((item) => item?.path)?.[0]
      //     }`
      //   );
      // }
      else if (allowedFaculitiesComponent?.length > 0) {
        navigate(
          `/${allowedFaculitiesComponent?.map((item) => item?.path)?.[0]}`
        );
      } else if (allowedSalesComponent?.length > 0) {
        navigate(`/${allowedSalesComponent?.map((item) => item?.path)?.[0]}`);
      } else if(allowedSettingsComponent?.length > 0) {
        navigate(
          `/settings/${
            allowedSettingsComponent?.map((item) => item?.path)?.[0]
          }`
        );
      }
      else {
        navigate("/login")
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <ProtectRoute>
      <Outlet />
    </ProtectRoute>
  );
};

export default Index;
