import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../../api";
import { subDays } from "date-fns";

const initialState = {
  currentPage: 1,
  scrollPage: 1,
  activeFilter: 1,
  currentFilter: null,
  currentPageSize: 10,
  startDate: subDays(new Date(), 30),
  endDate: new Date(),
  clearSelection: false,
  queryData: {},
  queryStatus: "idle",
  sortBy: "",
  sortOrder: "desc",
  search: "",
  streamData: {},
  slotData: [],
  statusData: {},
  statusFilterData: {},
  nationalityData: {},
  userData: {},
  is_edit: false,
  selectedItemsDetails: {},
  selectedCourseId: "",
  showCreateModal: false,
  end_date_filter: "",
  course_status: "",
  courseModuleList: [],
  filterShow: false,
};

export const getStudentsDataByID = createAsyncThunk(
  "student/show",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/show/${params}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStreams = createAsyncThunk(
  "students/getStreams",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/stream`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStatus = createAsyncThunk(
  "student/course-status",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/course-status`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getFilterStatus = createAsyncThunk(
  "student/student-course-status",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/student-course-status`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getNationality = createAsyncThunk(
  "/v1/student/nationality",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/nationality`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCourseAmount = createAsyncThunk(
  "/v1/student/fetch-course-amount",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/fetch-course-amount?course_id=${params.course_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsers = createAsyncThunk(
  "students/getUsers",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/list-user-sales`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCourseModuleList = createAsyncThunk(
  "course/course-modules",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/course/course-modules/${Id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCourseModuleSlots = createAsyncThunk(
  "course/slots",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`/v1/student/get-student-time-slot?faculty_id=${params?.faculty_id}&student_id=${params?.student_id}&course_id=${params?.course_id}&course_module_id=${params?.course_module_id}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteStudents = createAsyncThunk(
  "students/deleteStudents",
  async (Id, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/student/delete/${Id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateStudents = createAsyncThunk(
  "students/updateStudents",
  async ({ id, data }, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post(`/v1/student/update/${id}`, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const createStudents = createAsyncThunk(
  "students/createStudents",
  async (data, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.post("/v1/student/store", data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getStudentsScrollListData = createAsyncThunk(
  "students/getStudentsScrollListData",
  async (params, { rejectWithValue }) => {
    const getParams = (key) => {
      if (params?.[key]) {
        return `${key}=${params[key]}&`;
      } else {
        return "";
      }
    };

    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `/v1/student?${getParams("start_date")}${getParams(
          "end_date"
        )}${getParams("filter")}${getParams("sort_by")}sort_order=${
          params?.sort_order || "desc"
        }&length=${params?.length || "10"}&${getParams("search")}page=${
          params?.page || 1
        }`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const studentsSlice = createSlice({
  name: "students",
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      action.payload(state);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentsScrollListData.pending, (state, action) => {
      state.queryStatus = "pending";
    });
    builder.addCase(getStudentsScrollListData.fulfilled, (state, action) => {
      if (state.queryData?.data && !action.meta?.arg?.clearState) {
        if (action?.payload?.data?.data?.rows?.enquiries?.data) {
          state.queryData = {
            ...action?.payload?.data?.data?.rows?.enquiries,
            queryStatus: "fulfilled",
            data: [
              ...state.queryData?.data,
              ...action?.payload?.data?.data?.rows?.enquiries?.data,
            ],
          };
        } else {
          state.queryData = {
            ...action?.payload?.data?.data?.rows?.enquiries,
            queryStatus: "fulfilled",
            data: [...state.queryData?.data],
          };
        }
      } else {
        state.queryData = {
          ...action?.payload?.data?.data?.rows?.enquiries,
          queryStatus: "fulfilled",
          page: 1,
        };
      }
      state.queryStatus = "fulfilled";
    });
    builder.addCase(getStudentsScrollListData.rejected, (state, action) => {
      state.queryStatus = "failed";
    });
    builder
      .addCase(getStudentsDataByID.pending, (state) => {
        state.isLoading = true;
        state.selectedItemsDetails = {};
        state.error = null;
      })
      .addCase(getStudentsDataByID.fulfilled, (state, action) => {
        state.selectedItemsDetails = action.payload.data.data;
      })
      .addCase(getStudentsDataByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStatus.fulfilled, (state, action) => {
        state.statusData = action.payload.data.data;
      })
      .addCase(getStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getFilterStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getFilterStatus.fulfilled, (state, action) => {
        state.statusFilterData = action?.payload?.data?.data;
      })
      .addCase(getFilterStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action?.payload;
      });
    builder
      .addCase(getNationality.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getNationality.fulfilled, (state, action) => {
        state.nationalityData = action.payload.data.data;
      })
      .addCase(getNationality.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getUsers.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.userData = action.payload.data.data;
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getCourseModuleList.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCourseModuleList.fulfilled, (state, action) => {
        state.courseModuleList = action.payload.data.data;
      })
      .addCase(getCourseModuleList.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
    builder
      .addCase(getStreams.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getStreams.fulfilled, (state, action) => {
        state.streamData = action.payload.data.data;
      })
      .addCase(getStreams.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
      builder
      .addCase(getCourseModuleSlots.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getCourseModuleSlots.fulfilled, (state, action) => {
        state.slotData = action.payload.data.data;
      })
      .addCase(getCourseModuleSlots.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateConfig } = studentsSlice.actions;

export default studentsSlice.reducer;
