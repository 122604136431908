import { getAxiosInstance } from "../../../api";

export const getExportedData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/v1/student/export?end_date_filter=${params?.end_date_filter}
    &start_date=${params?.start_date}&end_date=${params?.end_date}
    &course_status=${params?.course_status}&stream_id=${params?.stream_id}&course_id=${params?.course_id}
    &branch_id=${params?.branch_id}&join_start_date=${params?.join_start_date}`,
      { responseType: "blob" }
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};
export const updateEditCourseData = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.post(
      `/v1/student/update-module-details/${params?.id}?weekly_hours=${params?.weekly_hours}&module_start_date=${params?.module_start_date}`
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

export const getIsButtonEnable = async (params) => {
  const api = await getAxiosInstance();
  try {
    const response = await api.get(
      `/v1/student/check-course-edit?course_id=${params?.course_id}&student_id=${params?.student_id}`
    );
    return response;
  } catch (error) {
    return error?.response?.data;
  }
};

// export const getIsButtonEnable = createAsyncThunk(
//   "student/check-course-edit",
//   async (params, { rejectWithValue }) => {
//     const api = await getAxiosInstance();
//     try {
//       const response = await api.get(
//         `/v1/student/check-course-edit/${params}`
//       );
//       return response;
//     } catch (error) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
