import { Button } from "@wac-ui-dashboard/wac_component_library";
import React from "react";
import useEditTimeSlotForm from "./useEditTimeSlotForm";
import Select from "react-select";

const EditTimeSlotForm = ({
  refetchEditTimeSchedule,
  timeSlotList,
  refetch,
}) => {
  const {
    formik,
    closeModal,
    daysList,
    courseList,
    facultyObj,
    moduleObj,
    courseObj,
    moduleList,
    studentList,
    facultyList,
    daysObj,
    studentObj,
  } = useEditTimeSlotForm({ refetchEditTimeSchedule, timeSlotList, refetch });
  return (
    <div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Faculty
        </label>
        <Select
          id="faculty_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.faculty_id && formik.errors.faculty_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={facultyList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={facultyObj}
          onBlur={formik.handleBlur("faculty_id")}
          onChange={(value) => {
            formik.setFieldValue("faculty_id", value?.value ?? "");
          }}
          isDisabled
        />
        {formik.touched.faculty_id && formik.errors.faculty_id && (
          <span className="error-text">{formik.errors.faculty_id}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Course
        </label>
        <Select
          id="course_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.course_id && formik.errors.course_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={courseList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={courseObj}
          onBlur={formik.handleBlur("course_id")}
          onChange={(value) => {
            formik.setFieldValue("course_id", value?.value ?? "");
          }}
          isDisabled
        />
        {formik.touched.course_id && formik.errors.course_id && (
          <span className="error-text">{formik.errors.course_id}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Course Module
        </label>
        <Select
          id="course_module_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.course_module_id &&
            formik.errors.course_module_id &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={moduleList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={moduleObj}
          onBlur={formik.handleBlur("course_module_id")}
          onChange={(value) => {
            formik.setFieldValue("course_module_id", value?.value ?? "");
          }}
          isDisabled
        />
        {formik.touched.course_module_id && formik.errors.course_module_id && (
          <span className="error-text">{formik.errors.course_module_id}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Student
        </label>
        <Select
          id="student_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik.touched.student_id && formik.errors.student_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={studentList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={studentObj}
          onBlur={formik.handleBlur("student_id")}
          onChange={(value) => {
            formik.setFieldValue("student_id", value?.value ?? "");
          }}
          isDisabled
        />
        {formik.touched.student_id && formik.errors.student_id && (
          <span className="error-text">{formik.errors.student_id}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Day
        </label>
        <Select
          id="day_id"
          isClearable={false}
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.touched?.day_id && formik?.errors?.day_id && " error"
          }`}
          classNamePrefix="pro-input"
          options={daysList}
          getOptionValue={(option) => option?.value}
          getOptionLabel={(option) => option?.label}
          value={daysObj}
          onBlur={formik.handleBlur(`day_id`)}
          onChange={(value) => {
            formik.setFieldValue(`day_id`, value?.value ?? "");
          }}
          isDisabled
        />
        {formik?.touched?.day_id && formik?.errors?.day_id && (
          <span className="error-text">{formik?.errors?.day_id}</span>
        )}{" "}
      </div>
      <div className="input-wrap pro-mb-4">
        <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
          Time Slot
        </label>
        <Select
          id="time_slot_ids"
          name="time_slot_ids"
          isClearable={false}
          isMulti
          placeholder={"Select"}
          className={`pro-input lg  ${
            formik?.touched?.time_slot_ids &&
            formik?.errors?.time_slot_ids &&
            " error"
          }`}
          classNamePrefix="pro-input"
          options={timeSlotList}
          getOptionValue={(option) => option?.id}
          getOptionLabel={(option) => option?.slot}
          value={timeSlotList?.filter((m) =>
            formik?.values?.time_slot_ids?.includes?.(m?.id)
          )}
          onBlur={formik.handleBlur(`time_slot_ids`)}
          onChange={(value) =>
            formik.setFieldValue(
              `time_slot_ids`,
              value.map((v) => v?.id)
            )
          }
          menuPlacement="auto"
        />
        {formik?.touched?.time_slot_ids && formik?.errors?.time_slot_ids && (
          <span className="error-text">{formik?.errors?.time_slot_ids}</span>
        )}{" "}
      </div>
      <div className="pro-d-flex pro-justify-end pro-pt-4">
        <Button
          className={"pro-btn-link lg "}
          onClick={() => closeModal()}
          type="button"
        >
          Cancel
        </Button>
        <Button
          className={"pro-btn-primary lg pro-ms-3"}
          type="submit"
          onClick={formik.handleSubmit}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export default EditTimeSlotForm;
