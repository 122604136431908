import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";

import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateConfig } from "../../../store/slices/Invoices/invoicesSlice";
import { useUpdatePaymentDataMutation } from "../../../store/queries/invoices";

const useAddPaymentForm = ({ refetch }) => {
  const dispatch = useDispatch();

  const state = useSelector((state) => state.invoices);

  const [updatePayment] = useUpdatePaymentDataMutation();

  const handleClose = () => {
    dispatch(
      updateConfig((state) => {
        state.showPaymentModal = false;
        state.receivedAmount = "";
        state.netPayableAmount = "";
      })
    );
  };

  const validation = Yup.object({
    receivedAmount: Yup.string()
      .test(
        "is-less-than-or-equal",
        `Received amount must be less than ${state.netPayableAmount}`,
        function (value) {
          const totalAmount = state.netPayableAmount; // Access the totalAmount field
          return value <= totalAmount;
        }
      )
      .required("*Received amount Required"),
    transaction_date: Yup.string().required("*Received date required"),
    currency_code: Yup.string().required("Please Select Currency code"),
  });

  const formik = useFormik({
    initialValues: {
      receivedAmount: "",
      transaction_date: "",
      currency_code: "AED",
    },
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      let data = {
        invoice_id: state?.IDs?.invoiceID,
        received_amount: parseInt(values?.receivedAmount),
        transaction_date: values?.transaction_date,
        currency_code: values?.currency_code,
      };
      updatePayment(data).then((response) => {
        if (response?.data?.status_code === 200) {
          toast.success("Payment Updated successfully ");

          dispatch(
            updateConfig((state) => {
              state.showPaymentModal = false;
            })
          );
          refetch();
        } else if (response?.data?.status_code === 404) {
          toast.error(response?.data?.message);
        } else {
          toast.error("Failed to Update Payment");
        }
      });
    },
  });

  let currencyList = [
    {
    label: "INR",
    value: "INR"
  },
  {
    label: "AED",
    value: "AED"
  },
  ]

  return { handleClose, formik, currencyList };
};

export default useAddPaymentForm;
