import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { storeAssignFaculty } from "../../../TimeTables/TimeTable/api";
import {
  useGetFacultyListDataQuery,
} from "../../../../store/queries/timeTable";
import { updateConfig } from "../../../../store/slices/Students/studentsProfileSlice";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { getIsButtonEnable } from "../../StudentsListing/api";
import { useSelector } from "react-redux";

const useAssignFacultyForm = ({
  courseRefetch,
}) => {
  const dispatch = useDispatch();

  const profileState = useSelector((state) => state?.studentProfile);
  useEffect(() => {
    formik.setValues({
      ...formik.values,
      faculty_id: profileState?.selectedFacultyData?.modules[0]?.faculty_id,
    });
    //eslint-disable-next-line
  }, []);


  const [initialData, setInitialData] = useState({
    faculty_id: "",
    student_course_module_id: profileState?.selectedFacultyData?.modules[0]?.id,
  });
  const { data: facultyData } = useGetFacultyListDataQuery();

  const facultyList = facultyData?.data?.map(({ user: { full_name }, id }) => ({
    label: full_name,
    value: id,
  }));
  const handleButtonDisplay = (params) => {
    getIsButtonEnable(params).then((response) => {
      if (response?.data?.status_code === 200) {
        dispatch(
          updateConfig((state) => {
            state.isButtonFalse = response?.data?.data?.is_editable;
          })
        );
      } else {
        dispatch(
          updateConfig((state) => {
            state.isButtonFalse = response?.data?.data?.is_editable;
          })
        );
      }
    });
  };

  const validation = Yup.object({
    faculty_id: Yup.string().required("Choose Faculty")
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validation,
    enableReinitialize: true,
    onSubmit: (values) => {
      storeAssignFaculty(values).then((response) => {
        if (response?.data?.status_code === 200) {
          handleCloseModal();
          courseRefetch();
          toast.success(`Faculty Assigned Successfully!`);
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else if (response?.data?.status_code === 422) {
          toast.error(response?.data?.message);
          handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        } else {
          toast.error(response?.message);
          handleCloseModal();
          dispatch(
            updateConfig((state) => {
              state.clearSelection = true;
            })
          );
        }
      });
    },
  });

  const handleFacultyChange = (value) => {
    // setFacultyId(value?.value);
    formik.setFieldValue("faculty_id", value?.value ?? "");
  };
  const handleCloseModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showAssignFacultyModal = false;
      })
    );
  };
  
  let facultyObj = facultyList?.filter(
    (item) => item?.value === formik?.values?.faculty_id
  );

  

  return {
    formik,
    facultyObj,
    facultyList,
    handleCloseModal,
    handleFacultyChange,
  };
};

export default useAssignFacultyForm;
