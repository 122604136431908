import { useMemo, useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import { updateConfig as globalUpdateConfig } from "../../../store/slices/Global";
import { getFormatedDate } from "../../../utils/functions/table";
import { addDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  deleteCourses,
  getCourseDataByID,
  updateConfig,
} from "../../../store/slices/Courses/coursesSlice.js";
import {
  useGetCourseDataQuery,
  useUpdateCoursesListFieldsDataMutation,
} from "../../../store/queries/courses";
import useObserver from "../../../utils/hooks/useObserver";
import { IconText } from "@wac-ui-dashboard/wac_component_library";
import { getStreams } from "../../../store/slices/Enquiries/enquirySlice";
import { toast } from "react-toastify";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const useCourse = (dashboard) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const menuState = useSelector((state) => state.courses);
  const [activeFilter, setActiveFilter] = useState(2);
  const [selectedId, setSelectedId] = useState([]);
  const [skip, setSkip] = useState(true);
  const [updateCoursesFields] = useUpdateCoursesListFieldsDataMutation();
  const { showCreateModal, selctedId } = useSelector((state) => state.courses);
  const { tableFields, showEditModal } = useSelector((state) => state.global);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [selectedItemID, setSelectedItemID] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const [imageData, setImageData] = useState("");
  const debouncedSearchTerm = useDebounce(menuState.search, 1000);

  const {
    data: mainData = {},
    isFetching,
    isSuccess,
    isLoading,
    refetch,
  } = useGetCourseDataQuery({
    sort_by: menuState.sortBy,
    sort_order: menuState.sortOrder,
    search: debouncedSearchTerm,
    start: menuState.startDate,
    end: menuState.endDate,
    length: menuState.currentPageSize,
    page: menuState.currentPage,
  });
  const hasCreatePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_create")
    );
    return permission?.[0]?.can_create ?? 0;
  }, [mainData]);
  const hasEditPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_edit")
    );
    return permission?.[0]?.can_edit ?? 0;
  }, [mainData]);
  const hasViewPermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_view")
    );
    return permission?.[0]?.can_view ?? 0;
  }, [mainData]);
  const hasDeletePermission = useMemo(() => {
    let permission = mainData?.data?.permissions?.filter((p) =>
      Object.keys(p).includes("can_delete")
    );
    return permission?.[0]?.can_delete ?? 0;
  }, [mainData]);

  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);

  let paginationOptions = [
    { label: "10 Per page", value: 10 },
    { label: "20 Per page", value: 20 },
    { label: "30 Per page", value: 30 },
  ];

  const [date, setDate] = useState({
    startDate: new Date(),
    endDate: addDays(new Date(), 7),
    key: "selection",
  });

  const handleSort = (label) => {
    if (menuState.sortBy === label) {
      dispatch(
        updateConfig((state) => {
          state.sortOrder = menuState.sortOrder === "asc" ? "desc" : "asc";
        })
      );
    } else {
      dispatch(
        updateConfig((state) => {
          state.sortBy = label;
          state.sortOrder = "asc";
        })
      );
    }
  };

  const handleSearch = (e) => {
    dispatch(
      updateConfig((state) => {
        state.search = e.target.value;
      })
    );
  };

  const closeModal = () => {
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = false;
        state.is_edit = false;
        // state.selectedItemsDetails = ""
      })
    );
  };

  const handleEditAction = (data) => {
    // setSkip(() => false);
    setSelectedId(data?.[0]);
    dispatch(getCourseDataByID(data?.[0]));
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
        state.is_edit = true;
      })
    );
  };
  const handleEditClick = () => {
    // dispatch(
    //   updateConfig((state) => {
    //     state.selectedItemsDetails = enquiryViewData?.data ?? "";
    //   })
    // );
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = true;
      })
    );
  };

  const closeEditModal = () => {
    setSkip(() => true);
    dispatch(
      globalUpdateConfig((state) => {
        state.showEditModal = false;
      })
    );
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
  };

  const getRow = (feild, data) => {
    const rows = {
      common: (feild, data) => <p className="pro-mb-0">{data[feild]}</p>,
      branch: (feild, data) => (
        <p className="pro-mb-0">{data[feild]?.branch_name}</p>
      ),
      image: (feild, data) => {
        return (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.image_url)}
          />
        );
      },
      thumbnail: (feild, data) => {
        return (
          <IconText
            icon={
              <span className="material-symbols-outlined x4">imagesmode</span>
            }
            title={`${"View Photo"}`}
            onClick={() => handleViewImage(data?.thumbnail_url)}
          />
        );
      },
    };

    return rows?.[feild]?.(feild, data) ?? rows["common"](feild, data);
  };

  const handleViewImage = (data) => {
    setShowImageModal(() => true);
    setImageData(data);
  };

  const closeImageModal = () => {
    setShowImageModal(() => false);
    setImageData("");
  };

  const handleDateChange = (range) => {
    setDate(range);
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.startDate = getFormatedDate(range.startDate);
        state.endDate = getFormatedDate(range.endDate);
      })
    );
  };

  const handlePagination = (page) => {
    dispatch(
      updateConfig((state) => {
        state.currentPage = page;
      })
    );
  };

  const handlePageSize = ({ value: page_size }) => {
    dispatch(
      updateConfig((state) => {
        state.currentPageSize = page_size;
        state.currentPage = 1;
      })
    );
  };

  let actionOptions = [
    { label: "Edit Columns", value: 0 },
    { label: "Export view", value: 1 },
  ];

  const handleActionChange = (item) => {
    if (item?.value === 0) {
      dispatch(
        globalUpdateConfig((state) => {
          state.showEditModal = true;
        })
      );
    }
  };

  const handleClearClick = () => {
    setDate({
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: "selection",
    });
    dispatch(
      updateConfig((state) => {
        state.currentPage = 1;
        state.currentPageSize = 10;
        state.startDate = null;
        state.endDate = null;
        state.sortBy = "";
        state.sortOrder = "";
        state.search = "";
        state.showCreateModal = false;
        state.showInvoiceModal = false;
        state.selctedId = "";
        state.invoiceLoading = false;
      })
    );
  };
  useEffect(() => {
    if (menuState.clearSelection) {
      dispatch(
        updateConfig((state) => {
          state.clearSelection = false;
        })
      );
    }
    // eslint-disable-next-line
  }, [menuState.clearSelection]);
  const handleDashboardRedirect = (filter) => {
    navigate("/payment/mainData");
  };

  const handleLoadMore = useCallback(() => {
    if (isSuccess) {
      dispatch(
        updateConfig((state) => {
          state.scrollPage = mainData?.data?.rows?.enquiries?.current_page + 1;
        })
      );
    }
    //eslint-disable-next-line
  }, [dispatch, isSuccess, mainData]);

  const { lastElement } = useObserver({
    loading: isLoading,
    hasMore:
      mainData?.data?.rows?.enquiries?.per_page *
        mainData?.data?.rows?.enquiries?.current_page <
      mainData?.data?.rows?.enquiries?.total,
    callback: handleLoadMore,
  });
  const handleDelete = async (ids) => {
    setShowDeleteConfirm(true);
    setSelectedItemID(ids);
  };

  const handleDeleteAction = async () => {
    if (Array.isArray(selectedItemID) && showDeleteConfirm) {
      dispatch(deleteCourses(selectedItemID[0]))
        // .unwrap()
        .then((result) => {
          if (result?.payload?.status_code === 200) {
            toast.success(result?.payload?.message);
          } else {
            toast.error(result?.payload?.message);
          }
          if (result) {
            refetch();
            setSelectedItemID("");
            setShowDeleteConfirm(false);
          }
        })
        .catch((err) => {
          setShowDeleteConfirm(false);
        });
    } else {
      setShowDeleteConfirm(false);
    }
  };

  const handleCreateClick = () => {
    dispatch(
      updateConfig((state) => {
        state.selectedItemsDetails = "";
      })
    );
    dispatch(
      updateConfig((state) => {
        state.showCreateModal = true;
      })
    );
  };

  return {
    mainData,
    menuState,
    currentPage: menuState.currentPage,
    showCreateModal,
    hasCreatePermission,
    isFetching,
    updateCoursesFields,
    isLoading,
    handleCreateClick,
    date,
    // optionsData,
    showEditModal,
    tableFields,
    paginationOptions,
    selctedId,
    actionOptions,
    handleDelete,
    // enquiryViewData,
    // hasExportPermission,
    showImageModal,
    imageData,
    selectedId,
    hasDeletePermission,
    hasEditPermission,
    lastElement,
    // updateEnquiryStatus,
    handleEditAction,
    closeImageModal,
    handleDashboardRedirect,
    handleActionChange,
    refetch,
    // updateEnquiryFields,
    handleSort,
    handleSearch,
    handleCreateClick,
    closeModal,
    getRow,
    handleEditClick,
    closeEditModal,
    handleDateChange,
    handlePagination,
    handlePageSize,
    handleClearClick,
    setShowDeleteConfirm,
    showDeleteConfirm,
    handleDeleteAction,
    imageData,
  };
};

export default useCourse;
